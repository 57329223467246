<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="custom-input-group" >
              <div class="custom-input">
                  <div class="eye-area" >
                    <EyeFill /> D
                  </div>
                  <div class="custom-input eye-box-container">
                    <div class="text-area type-2 with-br" @click="openOptions('esfericoDireito')">
                      <span >Esférico</span>
                      <div>
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control text-center"
                          :value="form.fields.olhoDireitoEsferico"
                          :disabled="!canEdit"
                          @input="el => debounceInput('olhoDireitoEsferico', el.target.value)"
                        />
                        <MultipleEyesOptions
                          v-if="activeEyeBox ==='esfericoDireito' && canEdit"
                          :form="form"
                          activeInput="esfericoDireito"
                          :setActiveInput="setActiveEyeBox"
                          @select="({ key, value }) => updateForm(key, value)"
                          @blur="updateMedicalRecord(form)"
                          :options="form.fields.olhoDireitoEsferico !== null ?
                            (parseFloat(form.fields.olhoDireitoEsferico.replace(',', '.')) < 0.00 ?
                              optionsNegative :
                                optionsPositive) :
                            optionsPositive"
                        />
                      </div>
                    </div>
                    <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoEsferico', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsferico', null)"><p>-</p></button>
                      </div>
                    </div>
                  </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('cilindricoDireito')"
                  >
                    <span>Cilíndrico</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoCilindrico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoCilindrico', el.target.value)"
                      >
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='cilindricoDireito' && canEdit"
                        :form="form"
                        activeInput="cilindricoDireito"
                        :setActiveInput="setActiveEyeBox"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoDireitoCilindrico !== null ?
                          (parseFloat(form.fields.olhoDireitoCilindrico.replace(',', '.')) < 0.00 ?
                            optionsNegative :
                              optionsPositive) :
                          optionsNegative"
                        />
                    </div>
                  </div>

                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindrico', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindrico', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('eixoDireito')"
                  >
                    <span>Eixo</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoEixo"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoEixo', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='eixoDireito' && canEdit"
                        :form="form"
                        activeInput="eixoDireito"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 1, 'olhoDireitoEixo', 'eixo');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 1, 'olhoDireitoEixo', 'eixo')"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2"
                    @click="openOptions('AdicaoDireito')"
                  >
                    <span>Adição</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center border-top-right"
                        :value="form.fields.olhoDireitoAdicao"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoAdicao', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='AdicaoDireito' && canEdit"
                        :form="form"
                        activeInput="AdicaoDireito"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicao', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicao', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>
              </div>

              <div class="custom-input no-bb">
                  <div class="eye-area">
                  <EyeFill /> E
                  </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('esfericoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEsferico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEsferico', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='esfericoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="esfericoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoEsquerdoEsferico !== null ?
                          (parseFloat(form.fields.olhoEsquerdoEsferico.replace(',', '.')) < 0.00 ?
                            optionsNegative :
                              optionsPositive) :
                          optionsPositive"
                        />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsferico', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsferico', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>


                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('cilindricoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoCilindrico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoCilindrico', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='cilindricoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="cilindricoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoEsquerdoCilindrico !== null ?
                          (parseFloat(form.fields.olhoEsquerdoCilindrico.replace(',', '.')) < 0.00 ?
                            optionsNegative :
                            optionsPositive) :
                          optionsNegative"
                        />
                    </div>
                  </div>

                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindrico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindrico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>


                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2 with-br"
                    @click="openOptions('eixoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEixo"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEixo', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='eixoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="eixoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 1, 'olhoEsquerdoEixo', 'eixo');"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixo', 'eixo')"><p>-</p></button>
                      </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div
                    class="text-area type-2"
                    @click="openOptions('AdicaoEsquerdo')"
                  >
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center border-bottom-right"
                        :value="form.fields.olhoEsquerdoAdicao"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoAdicao', el.target.value)"
                      />
                      <MultipleEyesOptions
                        v-if="activeEyeBox ==='AdicaoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="AdicaoEsquerdo"
                        :setActiveInput="setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicao', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicao', null)"><p>-</p></button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <button
            class="btn blue-underline button-copy"
            @click="copyEyeValues"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <v-copy-icon />
          </button>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { debounce } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import MultipleEyesOptions from '@/components/Attendance/Forms/Components/MultipleEyesOptions'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Copy from '@/assets/icons/copy.svg'

export default {
  components: { FormWrapper, MultipleEyesOptions, EyeFill, 'v-copy-icon' : Copy, },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.autorrefracaoEstatica,
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  data() {
    return {
      activeEyeBox: String,
      optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
      optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
      optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/autorrefracaoEstatica', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    openOptions(input){
      this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    copyEyeValues() {
      this.updateForm(`olhoEsquerdoEsferico`, this.form.fields.olhoDireitoEsferico)
      this.updateForm(`olhoEsquerdoCilindrico`, this.form.fields.olhoDireitoCilindrico)
      this.updateForm(`olhoEsquerdoEixo`, this.form.fields.olhoDireitoEixo)
      this.updateForm(`olhoEsquerdoAdicao`, this.form.fields.olhoDireitoAdicao)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    hasAutoRefractionValues() {
      return this.preConsultation && (
        this.preConsultation.auto_refraction_addition_l !== null ||
        this.preConsultation.auto_refraction_addition_r !== null ||
        this.preConsultation.auto_refraction_cylindrical_l !== null ||
        this.preConsultation.auto_refraction_cylindrical_r !== null ||
        this.preConsultation.auto_refraction_axis_l !== null ||
        this.preConsultation.auto_refraction_axis_r !== null ||
        this.preConsultation.auto_refraction_spherical_l !== null ||
        this.preConsultation.auto_refraction_spherical_r !== null
      )
    },
    changeInputValue(change, amount, key, type){
      if(this.form.fields[key] === '' || this.form.fields[key] === null){
        this.form.fields[key] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[key])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(0)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(0)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'º')
              }
          }

          else if(!(isNaN(parseFloat(this.form.fields[key])))){
            if(change === 'increase'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(2)).replace('.', ','))

            } else if(change === 'decrease'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(2)).replace('.', ','))
            }
            if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
              this.updateForm(key, '+'+this.form.fields[key])
            }
        }
        this.updateMedicalRecord(this.form)
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.getAutoRefraction{

  &:hover {
    .order-body {
      display: block;
    }
  }
  .order-body {
    display: none;
    background-color: #fff;
    border: 1px solid var(--neutral-200);
    border-radius: 4px;
    padding: 5px 25px;
    position: absolute;
    z-index: 5;
    box-shadow: 10px 5px 5px var(--neutral-200);
  }
}
.eyeValueChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 !important;
  position: relative;
  margin-left: -30px !important;
  z-index: 5;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;

    button {
      height: 20px;
      width: 30px;
      color: var(--greys-60);
      background-color: #fff;
      position: relative !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1.5px solid var(--blue-100) !important;

      p {
        font-weight: 700;
      }

    }
  }
}
.refraction {
  color: var(--blue-500);
  text-decoration: underline;
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: 700;
}
.disabled {
  color: var(--neutral-200);
}
.eye-box-container {
  .eyeValueChange {
    visibility: hidden;
  }
  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}
.custom-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin: 24px 0 16px 0;

  .custom-input {
    flex: 1;
    height: 38px;
    border: 8px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--neutral-300);
    justify-content: center;

    .eye-area {
      width: 60px;
      background-color: var(--neutral-100);
      border-right: 1px solid var(--neutral-300);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--type-active);
        margin-right: 4px;
      }
    }

    &.no-bb {
      border-bottom: 0;

      .eye-area {
        border-radius: 0 0 0 8px;
      }
    }

    .text-area {
      flex: 1;
      line-height: 55px;
      margin-left: 16px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);

      .form-control {
        border: 0 !important;
        border-radius: 0 !important;

        &.with-bbr {
          border-radius: 0 0 8px 0 !important;
        }

        &.with-btr {
          border-radius: 0 8px 0 0 !important;
        }
      }

      &.type-2 {
        position: relative;
        margin-left: 0;
        text-align: center;

        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }
      &.with-br {
        border-right: 1px solid var(--neutral-300) !important;
      }

      &.with-brtr {
        border-radius: 0 8px 0 0 !important;
      }

      &.with-brbr {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }
}
.border-top-right {
  border-radius: 0 8px 0 0 !important;
}
.border-bottom-right {
  border-radius: 0 0 8px 0 !important;
}
</style>
